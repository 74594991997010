<template>
  <Blacklist />
</template>

<script>
import Blacklist from '@/components/admin/blacklist/index.vue';

export default {
    name: 'AdminBlacklist',
    components: {
        Blacklist,
    },
};
</script>
